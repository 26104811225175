import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)

// let mm = gsap.matchMedia();




// scrollback functionality

function debounce(func, timeout = 10){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

const header = document.getElementById("header")

var current = window.scrollY
var paddedUp = 0
var paddedDown = 0


function saveInput(){
  if (location.pathname === "/") {

    if(window.scrollY > 100) {
      header.classList.add("header-active")  
    } else {
        header.classList.remove("header-active")
    }
  } else {
    if(window.scrollY > 50) {
      if(window.scrollY < current){
        if(window.scrollY < paddedUp) {
          header.classList.add("header-active")
        }
        paddedDown = window.scrollY + 50
        
      } else {
        if(window.scrollY > paddedDown) {
          header.classList.remove("header-active")

          paddedUp = window.scrollY - 50
        }
       
      }
      current = window.scrollY
  } else {
      header.classList.add("header-active")
  }
  }
 
}

const logoScroll = debounce(() => saveInput());
window.addEventListener("scroll", logoScroll);


// mobile nav functionality
const mobileNav = document.getElementById("mobile-nav-menu")
const navBtn = document.getElementById("mobile-nav-btn")
const navEx = document.getElementById("nav-menu-exit-btn")

if(navEx){
  navEx.addEventListener("click", function() {
      if(mobileNav.classList.contains("mobile-nav-active")) {
        mobileNav.classList.remove("mobile-nav-active")
        
      }   
  })
}
if(navBtn){
navBtn.addEventListener("click", function() {
    if(!mobileNav.classList.contains("mobile-nav-active")) {
      mobileNav.classList.add("mobile-nav-active")
      
    }
})
} 

// About Swiper
const aboutDescriptions = document.querySelectorAll(".about-slide-description")


const aboutSwiper = new Swiper('.about-swiper', {
    
    direction: 'horizontal',
    loop: false,
    effect: 'fade',
    crossFade: true,
    speed: 2000,
    slidesPerView: 1,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '"></span>';
      },
    },
    
    navigation: {
        nextEl: ".about-swiper-button-next",
        prevEl: ".about-swiper-button-prev",
    },
    on: {
        init: function () {
          // do something
        },
    }
    
});



aboutSwiper.on('slideChange', function (e) {
    
    if(aboutDescriptions){
        for (let i = 0; i < aboutDescriptions.length; i++) {
            const el = aboutDescriptions[i];
            let index = parseInt(getAboutNumber(el.id))
            if(index === e.activeIndex){
                el.classList.add("about-slide-description-active")
                gsap.fromTo(el, {alpha: 0}, {alpha: 1, duration: 2})
                
            }else{
                el.classList.remove("about-slide-description-active")
            }
            
        }
    }

});


function getAboutNumber(str) {
    return str.split('about-slide-description-')[1];
}


// Merch Swiper 
const merchSwiper = new Swiper('.merch-swiper', {
    
  direction: 'horizontal',
  loop: false,
  speed: 300,
  slidesPerView: 1,
  spaceBetween: 25
  
});

const merchThumbnails = document.querySelectorAll(".merch-thumbnail")

if(merchThumbnails.length){
  for (let i = 0; i < merchThumbnails.length; i++) {
    const el = merchThumbnails[i];
    let slideIndex = i
    el.addEventListener("click", () => {
      merchSwiper.slideTo(slideIndex)

      for (let i = 0; i < merchThumbnails.length; i++) {
        const al = merchThumbnails[i];
        if(i === slideIndex ){
          al.classList.remove("border-white")
          al.classList.add("border-black")
        } else {
          al.classList.add("border-white")
          al.classList.remove("border-black")
        }
      }
    })
  }
}

// Media Page
const mediaContainers = document.querySelectorAll(".media-container")
const mediaThumbnails = document.querySelectorAll(".thumbnail")
const mediaBtns = document.querySelectorAll(".media-btn")

if(mediaContainers && mediaBtns){
    for (let i = 0; i < mediaContainers.length; i++) {
        const iframe = mediaContainers[i].querySelector('iframe');
        const btn = mediaBtns[i];
        const thumbnail = mediaThumbnails[i];
        btn.addEventListener("click", () => {
            thumbnail.classList.add("hidden")
            iframe.src += "&autoplay=1";
        })

        thumbnail.addEventListener("click", () => {
            thumbnail.classList.add("hidden")
            iframe.src += "&autoplay=1";
        })
    }
    
}

// scroll below fold button
const foldBtn = document.getElementById("scroll-below-fold")

if(foldBtn){
  foldBtn.addEventListener('click', () => {
    window.scroll({
      top: window.innerHeight,
      behavior: "smooth"
    })
  })
}
